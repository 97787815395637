//@import "bootstrap/functions";
//@import "bootstrap/variables";
//@import "node_modules/bootstrap/scss/functions";
//@import "node_modules/bootstrap/scss/variables";
//@import "node_modules/bootstrap/scss/mixins";
//
//$theme-colors: (
//  "primary": #D43790,
//  "secondary": $secondary,
//  "success": $success,
//  "info": $info,
//  "warning": $warning,
//  "danger": $danger,
//  "light": $light,
//  "dark": $dark
//);
//$tertiary: #3fb247;
//
//$custom-theme-colors:map-merge($theme-colors, (
//  "tertiary": $tertiary
//));
//
//
//$theme-colors: map-merge($theme-colors, $custom-theme-colors);
//$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
//$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
//$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
//$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");
//
$primary: #d43790;

@import "bootstrap";

.event-card {
  transition: transform 0.2s, box-shadow 0.2s;
}

.event-card:hover {
  transform: scale(1.05); /* Add a scaling effect on hover */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add a shadow effect on hover */
}

.event-info {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

//@import '~bootstrap/scss/bootstrap.scss';
