.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-bkg {
  display: table;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background: linear-gradient(to right, #640248, #b3298d, #6e058b);
  background-blend-mode: overlay;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.event-bkg {
  display: table;
  width: 100%;
  height: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url("assets/images/headerBkg.jpg");
  background-color: #282c34;
  background-blend-mode: overlay;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.contact-bkg {
  display: table;
  width: 100%;
  height: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url("assets/images/contact.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  background-blend-mode: overlay;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.learn-btn:hover {
  background-color: #c9c7c9 !important;
}
.icon-bkg {
  font-size: 38px;
  margin-bottom: 20px;
  transition: all 0.5s;
  color: #fff;
  width: 100px;
  height: 100px;
  padding: 30px 0;
  border-radius: 50%;
  background: linear-gradient(to right, #b3298d 0%, #d43790 100%);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}

.icon-bkg-sm {
  font-size: 38px;
  transition: all 0.5s;
  color: #fff;
  width: 75px;
  height: 75px;
  padding: 19px 0;
  border-radius: 50%;
  background: linear-gradient(to right, #b3298d 0%, #d43790 100%);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}

.nav-link.active {
  color: white !important;
  background-color: #d43790 !important;
}

.container-bkg {
  background: linear-gradient(to right, #4b1e3d, #d43790);
}
/* .wallet-bkg {
  background: linear-gradient(to right, #640248, #c61c96);
  height: auto;
} */
.wallet-bkg {
  display: table;
  width: 100%;
  height: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url("assets/images/wallet-bg.jpeg");
  background-blend-mode: overlay;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.trival-bkg {
  background: linear-gradient(to right, #3a0e81, #6228c0);
  height: auto;
}

.voting-bkg {
  background: linear-gradient(to right, #2409a9, #036422);
  height: auto;
}

.text-wrapper {
  width: 40%;
}

.pointer:hover {
  cursor: pointer;
}

.num-input {
  width: 20%;
}

.primary-accordion-header .accordion-button {
  background-color: #d43790;
  color: white;
}

.primary-accordion-header .accordion-button:not(.collapsed) {
  background-color: #d43790;
}

.carousel-container {
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.carousel-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
